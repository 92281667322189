import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation]);
const PartnersArea = () => {
    return (
        <section className="construct-partners-area section_50">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={0}
                            loop={true}
                            autoplay={{
                                "delay": 4000,
                                "disableOnInteraction": false
                            }}
                            // pagination={{
                            //     "clickable": true
                            // }}
                            // navigation={true}

                            className="mySwiper">

                            <SwiperSlide className="single-partners">
                                <a href="#">
                                    <img src="assets/img/brand-1.png" alt="partners" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide className="single-partners">
                                <a href="#">
                                    <img src="assets/img/brand-2.png" alt="partners" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide className="single-partners">
                                <a href="#">
                                    <img src="assets/img/brand-3.png" alt="partners" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide className="single-partners">
                                <a href="#">
                                    <img src="assets/img/brand-4.png" alt="partners" />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide className="single-partners">
                                <a href="#">
                                    <img src="assets/img/brand-5.png" alt="partners" />
                                </a>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnersArea;