import React from 'react';
import AboutTeam from '../../AboutPage/AboutTeam/AboutTeam';
import AboutUs from '../../AboutPage/AboutUs/AboutUs';
import Footer from '../../Share/Footer/Footer';
import Header from '../../Share/Header/Header';
import PartnersArea from '../../Share/PartnersArea/PartnersArea';
import SliderSwiper from '../../Share/SliderSwiper/SliderSwiper';
import AboutArea from '../AboutArea/AboutArea';
import HireArea from '../HireArea/HireArea';
import HomeSlider from '../HomeSlider/HomeSlider';
import LatestBlogArea from '../LatestBlogArea/LatestBlogArea';
import ProjectArea from '../ProjectArea/ProjectArea';
import QuoteArea from '../QuoteArea/QuoteArea';
import ServiceArea from '../ServiceArea/ServiceArea';
import TestimonialArea from '../TestimonialArea/TestimonialArea';

const Home = () => {
    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            {/* <HomeSlider></HomeSlider> */}
            <SliderSwiper></SliderSwiper>
            <AboutUs></AboutUs>
            <ServiceArea></ServiceArea>
            <AboutTeam></AboutTeam>
            <ProjectArea></ProjectArea>
            <AboutArea></AboutArea>
            <HireArea></HireArea>
            {/* <QuoteArea></QuoteArea> */}
            <TestimonialArea></TestimonialArea>
            {/* <LatestBlogArea></LatestBlogArea> */}
            {/* <PartnersArea></PartnersArea> */}
            {/* <Footer></Footer> */}
        </div>
    );
};

export default Home;