import React, {useState, useEffect} from 'react';
import img1 from '../../../Assets/services/img1.jpg'
import img2 from '../../../Assets/services/img2.jpg'
import img3 from '../../../Assets/services/img3.jpg'
import img4 from '../../../Assets/services/img4.jpg'
import { UrlContext } from '../../../App';
import { useContext } from 'react';

const ServiceArea = () => {

    const services = [
        {
            name: 'JTC EMPLOYMENT AGENCY',
            photo: `${img1}`,
            description: `JTC meanwhile earned a License for employment in foreign countries based on their capabilities. JTC is now an authorized Company that can recruit skilled and experienced personnel suitable for specific positions against any demand from any country and holds the responsibilities. Since JTC holds responsibilities, therefore, it recruits the individual after a process of understanding the candidate's capabilities and professionalism. JTC observes all Bangladeshi laws and policies for employment abroad.`
        },
        {
            name: 'IJTC TOURS & TRAVELS LTD.',
            photo: `${img2}`,
            description: `IJTC is a Government Licensed Tours & Travel Agency for domestic and abroad destinations. IJTC tour & Travels Ltd arranges Domestics Tours, inbound Packages, Outbound Packages, Domestic Air Ticketing, International Air Ticketing, Domestic Bus, Ship, Air Ticketing, Indian Rail & Air Ticketing, Worldwide Hotel Reservation, Corporate Meeting, and conference room reservations, River cruises, Car hiring services and Visit visa assistance for South Asia Country.`
        },
        {
            name: 'JTC CONSTRUCTION LTD.',
            photo: `${img3}`,
            description: `JTC Construction Ltd. is a construction company formed for the construction of buildings & projects. The company has been successfully managing its activities in Uttara for the past one year working on both small and large-scale Real estate projects, developer Company's services, Building Construction services & Steel Structural services. It started its operations in 2019 when Mr.Foyazur Rahaman Asad began his own Company "JTC Construction Ltd".`
        },
    ]

       const [companyServices, setCompanyServices] = useState(services)

       const url = useContext(UrlContext);
       const GET_URL = `${url}`;
       
       useEffect(() => {
           fetch(GET_URL + '/company/service/')
               .then(response => response.json())
               .then((data) => {
                //    console.log(data)
                if(data.length > 0){
                    setCompanyServices([])
                    setCompanyServices(data)
                }
               })
               .catch((err) => {
                   console.log(err);
               });
       }, []);



    return (
        <section className="construct-service-area section_100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-heading">
                            <h4>Our Services</h4>
                            <h2>We provides theoretical and practical training</h2>
                            <p>JTC is fully compliant and equipped with all training aids to make the candidates feel easy to work in any environment. Our students are now successfully working in Qatar, Singapore Malaysia, and other countries after passing all exams here at JTC.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        companyServices.map(service =>
                            <div className="col-md-4">
                                <div className="single-service">
                                    <div className="service-image">
                                        <a href="#">
                                            <img style={{maxHeight: '200px'}} src={service.photo} alt="service img" />
                                        </a>
                                    </div>
                                    <div className="service-text">
                                        <h3><a href="#">{service.name}</a></h3>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default ServiceArea;