import React, { useContext, useState, useEffect } from 'react';
import { EmployContext } from '../../../App';
import { UrlContext } from '../../../App';

const AboutTeam = () => {
    // const [employ] = useContext(EmployContext);
    const [teamMember, setTeamMember] = useState([]) 
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {

        fetch(GET_URL + '/company/team-member/')
            .then(response => response.json())
            .then((data) => {
                setTeamMember(data)
            })
            .catch((err) => {
                console.log(err);
            });
      }, []); 

    return (
        <section className="construct-project-page-area pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-heading">
                            <h4>Meet with</h4>
                            <h2>My Team</h2>
                            <p>We are also offering professional services consultation, ensures prompt customer service and most importantly we can help our clients' save time by providing the best <span>ONE-STOP SOLUTIONS</span> for the various government & statutory departments as per our clients’ needs.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="construct-isotope-project">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="clearfix projectContainer projectContainer3column">
                                        {
                                            teamMember.map( people =>
                                                <div key={people.name} style={{ maxHeight: '350px' }} className="element-item">
                                                    <div className="project-single-item img-contain-isotope">
                                                        <div>
                                                            <img src={people.photo} style={{ maxHeight: '350px', }} className="img-responsive" alt="Image" />
                                                        </div>
                                                        <div className="project-img-overlay">
                                                            <h4>{people.name}</h4>
                                                            <p>{people.designation}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutTeam;