import React from 'react';
import './WhatsappBtn.css'

const WhatsappBtn = ({whatsAppNumber}) => {
    return (
        <div className='whatsappBtn'>
            <a href={'https://wa.me/' +  whatsAppNumber  } target='_blank'>
                <div className='d-flex justify-content-center align-items-center btn btn-success'style={{ backgroundColor: "#28a745", borderColor: "#28a745", color: "#fff", boxShadow: "none" }}>
                    <i className="fa fa-whatsapp" style={{ fontSize: '1.5rem' }}></i>
                    <h6 className='whatsappText pl-2'>Contact Us</h6>
                </div>
            </a>
        </div>
    );
};

export default WhatsappBtn;