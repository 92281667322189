// import React, { useContext, useRef } from 'react';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { AddressContext, UrlContext } from '../../../App';
import Breadcromb from '../../Share/Breadcromb/Breadcromb';
import Gmap from '../../Share/Gmap/Gmap';
// import Header from '../../Share/Header/Header';


import './Contact.css';


import emailjs from '@emailjs/browser';
// import Footer from '../../Share/Footer/Footer';
const Contact = () => {
    const address = useContext(AddressContext);
    // const embeded_iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d29184.150242517524!2d90.32544597821217!3d23.888952728841556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sjtc%20terning%20center%20dhaka!5e0!3m2!1sen!2sbd!4v1697111873740!5m2!1sen!2sbd" width="100%" height="450" style="border:0;" allowfullscreen="True" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'; 
   
    const [offices, setoffices] = useState([])
    const [supportTeamContact, setsupportTeamContact] = useState([])
    const [companyInfo, setcompanyInfo] = useState({})
    const [embeded_iframe, setembeded_iframe] = useState('<iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d29184.150242517524!2d90.32544597821217!3d23.888952728841556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sjtc%20terning%20center%20dhaka!5e0!3m2!1sen!2sbd!4v1697111873740!5m2!1sen!2sbd" width="100%" height="450" style="border:0;" allowfullscreen="True" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>')

    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {

        fetch(GET_URL + '/company/info/')
            .then(response => response.json())
            .then((data) => {
                setcompanyInfo(data)
                 if(data.embeded_iframe){
                     setembeded_iframe(data.embeded_map)
                }
                
            })
            .catch((err) => {
                console.log(err);
            });


        fetch(GET_URL + '/company/office/')
            .then(response => response.json())
            .then((data) => {
                setoffices(data)
            })
            .catch((err) => {
                console.log(err);
            });

         fetch(GET_URL + '/company/support-team-contact/')
            .then(response => response.json())
            .then((data) => {
                setsupportTeamContact(data)
            })
            .catch((err) => {
                console.log(err);
            });

      }, []); 

    function iframe() {
        return {
          __html: embeded_iframe
        }
      }

    const sendEmail = (e) => {
        e.preventDefault();
        // console.log(e)

        emailjs.sendForm('service_ofnfu0n', 'template_x1o4dim', e.target, 'user_p3kw6IK3iU9Lrsh9qHCfv')
            .then((result) => {
                if (result) {
                    // alert(`${result.text} message send`);
                    // if (result) {
                    //     e.target.reset()
                    // }
                    // console.log(result)
                }
                // result ? alert("Hello! I am an alert box!!") :' ';
            }, (error) => {
                alert(`${error}`);
            });
    };


    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            <Breadcromb directory={'contact'} title={'CONTACT US'} page={'contact'}></Breadcromb>
            {/* <Gmap></Gmap> */}
          
            <section className="construct-contact-page-area section_100">
                <div className="container map-margin-bottom-100">
                        <div className='construct-contact-form-bottom'>
                            <div className="row">
                                    
                                    <div dangerouslySetInnerHTML={ iframe() } />
                                    
                            </div>   
                        </div>
                        
                </div>    
                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="construct-contact-desc">
                                <h3>You can Contact with us Alwayes</h3>
                                <p>{ companyInfo.contact_description  ? companyInfo.contact_description : "we are also offering professional services consultation, ensures prompt customer service and most importantly we can help our clients' save time by providing the best ONE-STOP SOLUTIONS for the various government & statutory departments as per our clients’ needs."}</p>
                            </div>
                        </div>
                    </div>
                    <div className="construct-contact-form-bottom">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="contact-form-left">
                                    <div className="single-contact-left">
                                        <div className="contact-icon">
                                            <i className="fa fa-phone text-center"></i>
                                        </div>
                                        <div className="contact-text">
                                            <h4>phone</h4>
                                            <p>{ companyInfo.highlighted_phone ? companyInfo.highlighted_phone : address.phone}</p>
                                            <p>{ companyInfo.phone  ? companyInfo.phone : address.mobile}</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-left">
                                        <div className="contact-icon">
                                            <i className="fa fa-envelope text-center"></i>
                                        </div>
                                        <div className="contact-text">
                                            <h4>Email</h4>
                                            <p>{ companyInfo.highlighted_email  ? companyInfo.highlighted_email : address.email}</p>
                                            <p>{ companyInfo.email  ? companyInfo.email : ''}</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-left">
                                        <div className="contact-icon">
                                            <i className="fa fa-home text-center"></i>
                                        </div>
                                        <div className="contact-text">
                                            <h4>Address</h4>
                                            <p>{ companyInfo.address  ? companyInfo.address : address.location}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="construct-contact-form-right">
                                    <form onSubmit={sendEmail}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    <input type="text" id="name" name="user_name" placeholder="Enter Your Name" />
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <p>
                                                    <input type="email" id="email" name="user_email" placeholder="Enter Your Email" />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>
                                                    <input type="text" id="subject" name="subject" placeholder="Your Subject" />
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <p>
                                                    <input type="tel" id="user_phone" name="phone" placeholder="Your Phone" />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <div className="col-md-12">
                                                <p>
                                                    <textarea id="message" name="message" placeholder="Message..."></textarea>
                                                </p>
                                                <p className="my-3">
                                                    <button type="submit">send message</button>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
            <section className='section_50'>
                <div className="container">
                    <div className="contact-left">
                        <div className="basic-info mb-5">
                            <h2 className="title-small text-center mb-5">More Contact info</h2>
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-history"></i>
                                            <small></small>
                                        </div>
                                        <p>{ companyInfo.available_day  ? companyInfo.available_day : 'Saturday - Thursday'}</p>
                                        <p>{ companyInfo.available_time  ? companyInfo.available_time : '09:00 am - 18.00 pm'}</p>
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-envelope"></i>
                                            <small></small>
                                        </div>
                                        <p>{ companyInfo.highlighted_email ? companyInfo.highlighted_email : 'jtc.asad@gmail.com'}</p>
                                        <p>{ companyInfo.email  ? companyInfo.email : ''}</p>
                                    </div>
                                </div>
                               
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-phone"></i>
                                            <small></small>
                                        </div>
                                        <p>Support Team</p>
                                        {
                                            supportTeamContact.map( support =>
                                                <p>{support.phone}</p>
                                                )
                                        }
                                        {/* <p>+8802224470414</p>
                                       
                                        <p>+880 1842 639331</p>
                                        <p>+880 1842 639332</p>
                                        <p>+880 1842 639334</p>
                                        <p>+880 1842 639337</p>
                                        <p>+880 1842 639338</p>
                                        <p>+880 1842 639339</p> */}
                                    </div>
                                </div>
                               { offices.map(office =>
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-map-marker"></i>
                                            <small></small>
                                        </div>
                                        <p>{office.name}</p>
                                        <p>{office.address}</p>
                                        <p> {  office.phone  ? '📞' + office.phone : ''}</p>
                                        <p> {  office.phone2  ? '📞' + office.phone2 : ''}</p>
                                    </div>
                                 </div>
                                )
                                }
                                {/* <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-map-marker"></i>
                                            <small></small>
                                        </div>
                                        <p>Bangladesh Office</p>
                                        <p>House - 18, Member Bari Road, Dag - 909, Nishatnagar, Diabari, Turag, Uttara, Dhaka-1230, Bangladesh</p>
                                        <p>Uttara, Dhaka, Bangladesh.</p>
                                        <p>📞 +8802224470414</p>
                                        <p>📞 +880 1813 639331</p>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-map-marker"></i>
                                            <small></small>
                                        </div>
                                        <p>UAE Office</p>
                                        <p>Al soor building (lifeline pharmacy),</p>
                                        <p>4th floor 403 Afkar al nas human resources P.O. Box 43551, Sharjah, UAE</p>
                                        <p>📞 +971 526 142521</p>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className="basic-info-item text-center">
                                        <div className="icon">
                                            <i className="fa fa-map-marker"></i>
                                            <small></small>
                                        </div>
                                        <p>Cambodia Office</p>
                                        <p>No. 1487J, St. 5, Phum ka Chrang Chamreh Ti Pir, Khan Russey keo, Phnom Penh, Kingdom of Cambodia.</p>
                                        <p></p><p>📞 +855 812 43000</p><p></p>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* <Footer></Footer> */}
        </div >
    );
};

export default Contact;