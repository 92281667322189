import React from 'react';

const AboutPromoBox = () => {
    return (
        <section className="construct-about-section-three section_t_100 section_b_70">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="single-abt-page-promo">
                            <div className="abt-page-icon">
                                <i className="fa fa-globe"></i>
                            </div>
                            <div className="abt-page-content">
                                <h3>The Best in Asia</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="single-abt-page-promo">
                            <div className="abt-page-icon">
                                <i className="fa fa-umbrella"></i>
                            </div>
                            <div className="abt-page-content">
                                <h3>Best Support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="single-abt-page-promo">
                            <div className="abt-page-icon">
                                <i className="fa fa-users"></i>
                            </div>
                            <div className="abt-page-content">
                                <h3>Professional Agents</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPromoBox;