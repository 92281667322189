import React from 'react';

const AboutArea = () => {
    return (
        <section className="construct-about-bottom-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="about-bottom-left section_100">
                            <div className="about-bottom-one">
                                <h3>about us and our priorities</h3>
                                <p>Recruitment of Bangladeshi working candidates in several companies on a permanent, contractual or temporary basis.</p>
                            </div>
                            <div className="about-bottom-right clearfix row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="about_list">
                                        <div className="single-about-list">
                                            <div className="about_icon">
                                                <i className="fa fa-building-o"></i>
                                            </div>
                                            <div className="about_text">
                                                <h4>Human Resource Management Services</h4>
                                                {/* <p>with experience</p> */}
                                            </div>
                                        </div>
                                        <div className="single-about-list">
                                            <div className="about_icon">
                                                <i className="fa fa-users"></i>
                                            </div>
                                            <div className="about_text">
                                                <h4>Consultations for Setting up New Businesses & Companies</h4>
                                                {/* <p>with experience</p> */}
                                            </div>
                                        </div>
                                        <div className="single-about-list">
                                            <div className="about_icon">
                                                <i className="fa fa-cogs"></i>
                                            </div>
                                            <div className="about_text">
                                                <h4>Consultations about Rules and Regulations of Bangladesh Government</h4>
                                                {/* <p>with experience</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="about_list">
                                        <div className="single-about-list">
                                            <div className="about_icon">
                                                <i className="fa fa-building-o"></i>
                                            </div>
                                            <div className="about_text">
                                                <h4>Assistance with Business & Company Registration</h4>
                                                {/* <p>with experience</p> */}
                                            </div>
                                        </div>
                                        <div className="single-about-list">
                                            <div className="about_icon">
                                                <i className="fa fa-users"></i>
                                            </div>
                                            <div className="about_text">
                                                <h4>Consultations regarding Employment Act</h4>
                                                {/* <p>with experience</p> */}
                                            </div>
                                        </div>
                                        <div className="single-about-list">
                                            <div className="about_icon">
                                                <i className="fa fa-cogs"></i>
                                            </div>
                                            <div className="about_text">
                                                <h4>Good Planning with <br /> experience</h4>
                                                {/* <h4>with experience</h4> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutArea;