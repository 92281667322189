import React, { useState, useContext, useEffect } from 'react';
import './SliderSwiper.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { UrlContext } from '../../../App';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper';

import img1 from '../../../Assets/slider/slider-bg1.jpg'
import img2 from '../../../Assets/slider/slider-bg2.jpg'
import img3 from '../../../Assets/slider/slider-bg3.jpg'
import img4 from '../../../Assets/slider/slider-bg4.jpg'

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);



const SliderSwiper = () => {
    const [sliderContent, setSliderContent] = useState([
        {
            image: `${img1}`,
            title: 'build your dream with',
            sub_title: 'passion.',
            description: 'We help construction innovate and grow.'
        },
        {
            image: `${img2}`,
            title: 'construction service ',
            sub_title: 'providors.',
            description: 'We help construction innovate and grow.'
        },
        {
            image: `${img3}`,
            title: 'build your dream with',
            sub_title: 'passion.',
            description: 'We help construction innovate and grow.'
        },
        {
            image: `${img4}`,
            title: 'construction service ',
            sub_title: 'providors.',
            description: 'We help construction innovate and grow.'
        }
    ])
    // const [sliderContent, setSliderContent] = useState([])
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;
    
    useEffect(() => {
        fetch(GET_URL + '/statics/banners/?page=home')
            .then(response => response.json())
            .then((data) => {
                console.log(data)
                setSliderContent(data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={0}
            loop={true}
            autoplay={{
                "delay": 4000,
                "disableOnInteraction": false
            }}
            // pagination={{
            //     "clickable": true
            // }}
            navigation={true}

            className="mySwiper">
            {
                sliderContent.map(slider =>
                    <SwiperSlide className='swiperSlider' style={{ height: '560px', background: `rgba(0, 0, 0, 0.3) url("${slider.image}") repeat scroll 0 0` }}>
                        <div className="construct-main-caption">
                            <div className="construct-caption-cell">
                                <div className="container">
                                    <div className="row textArea">
                                        <div className="col-md-7 ml-auto">
                                            <h2>{slider.title} <span>{slider.sub_title}</span></h2>
                                            <p>{slider.description}</p>
                                            <a href="#" className="construct-btn">start a project</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                )
            }
        </Swiper>
    );
};

export default SliderSwiper;