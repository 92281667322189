import React, { useState, useContext, useEffect } from 'react';
import { UrlContext } from '../../App';
import Breadcromb from '../Share/Breadcromb/Breadcromb';
// import Footer from '../Share/Footer/Footer';
// import Header from '../Share/Header/Header';

const Singapore = () => {

    const [admission_data, set_admission_data] = useState([])
    const [nextPage, setNextPage] = useState()
    const [previousPage, setPreviousPage] = useState()
    const [isPrevious, setIsPrevious] = useState(false)
    const [isNext, setIsNext] = useState(false)
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    let table_name = "client_info_list_by_code/singapore/"

    useEffect(() => {
        //logic for bringing product;
        fetch(GET_URL + table_name)
            .then(response => response.json())
            .then((data) => {
                if(data.next){
                    setNextPage(data.next.charAt(data.next.length - 1))
                }
                if(data.previous){
                    setPreviousPage(data.previous.charAt(data.previous.length - 1))
                }
                set_admission_data(data.results)
            })
            .catch((err) => {
                // console.log(err);
            });
    }, []);

    function goToNext(pageNumber){
       
        setIsNext(true)
        setIsPrevious(false)
        fetch(GET_URL + table_name + '?page=' + pageNumber)
        .then(response => response.json())
        .then((data) => {
            if(data.next){
                setNextPage(data.next.charAt(data.next.length - 1))
            }else{
                setNextPage(null)
            }
            if(data.previous){
                setPreviousPage(data.previous.charAt(data.previous.length - 1))
            }else{
                setPreviousPage(null)
            }
            set_admission_data(data.results)
        })
        .catch((err) => {
            // console.log(err);
        });

     }

     function goToPrevious(pageNumber){
        
        setIsNext(false)
        setIsPrevious(true)
        if(pageNumber == '/'){
            pageNumber = 1
        }
        fetch(GET_URL + table_name + '?page=' + pageNumber)
        .then(response => response.json())
        .then((data) => {
            console.log(data)
            if(data.next){
                setNextPage(data.next.charAt(data.next.length - 1))
            }else{
                setNextPage(null)
            }
            if(data.previous){
                setPreviousPage(data.previous.charAt(data.previous.length - 1))
            }else{
                setPreviousPage(null)
            }
            set_admission_data(data.results)
        })
        .catch((err) => {
            // console.log(err);
        });

     }
     function paginateBtnClasslassNames(className){
       return 'btn btn-light ' + className
     }

    // admission_data.map((item) => (
    //     console.log("each admission_data", item.date)
    // ))

    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            <Breadcromb directory={'Our Fly'} title={'Singapore'} page={'singapore'}></Breadcromb>

            <div style={{ overflow: "auto" }} className="container my-5">
                <div className="service-details">
                    <h3 className="title-small mb-30">Singapore Fly Our Students.</h3>

                    <table className="table table-bordered table-striped table-hover mt-4">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Passport No.</th>
                                <th>Flight Date</th>
                                <th>Trade</th>
                                <th>Company Name</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                admission_data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.passport}</td>
                                        <td>{item.flight_date}</td>
                                        <td>{item.trade}</td>
                                        <td>{item.company_name}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    <div className='pagination-container'>  
                        {
                            previousPage ?  <button onClick={() => goToPrevious(previousPage)} className ={ paginateBtnClasslassNames(isPrevious ?  'active' : ' ')} >  <i className='fa fa-arrow-left'></i></button> : ''
                        }
                        
                        {
                            nextPage ? <button onClick={() => goToNext(nextPage)} className={ paginateBtnClasslassNames(isNext ?  'active' : ' ')} ><i className='fa fa-arrow-right'></i></button> : ''
                        } 
                 
                    </div>
                </div>
            </div>
            {/* <Footer></Footer> */}
        </div>
    )
}
export default Singapore;   