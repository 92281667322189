import React from 'react';

const Error404 = () => {
    return (
        <div>
            {/* <!-- Breadcromb Area Start --> */}
            <section className="construct-breadcromb-area section_100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcromb">
                                <h2>404 notfound</h2>
                                <ul>
                                    <li><a href="index.html">home</a></li>
                                    <li>/</li>
                                    <li>404 page</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Not Found Area Start --> */}
            <section className="construct-notfound-area section_100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="notfound">
                                <h2>404</h2>
                                <h3>Sorry, Page you're looking for is not found</h3>
                                <form>
                                    <input type="email" name="email" placeholder="Enter Your Email Address" />
                                        <button type="submit" >
                                            <i className="fa fa-send-o" aria-hidden="true"></i>
                                        </button>
                                </form>
                                <a href="/" className="construct-btn">back to home page</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Not Found Area End --> */}
        </div>
    );
};

export default Error404;