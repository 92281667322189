import {React, useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import logo from "../../../Assets/Logo/logo.jfif";
import Navbar2 from '../Navbar2/Navbar2';
import WhatsappBtn from '../WhatsappBtn/WhatsappBtn';
import { UrlContext } from '../../../App';
const Header = () => {

    const [companyInfo, setcompanyInfo] = useState({})
    const [socialMedia, setSocialMedia] = useState([])


    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {
        fetch(GET_URL + '/company/info/')
            .then(response => response.json())
            .then((data) => {
                console.log(data)
               setcompanyInfo(data)
            })
            .catch((err) => {
                console.log(err);
            });
        fetch(GET_URL + '/company/social-media/')
            .then(response => response.json())
            .then((data) => {
                if(data.length > 0){
                    setSocialMedia(data)
                }
               
            })
            .catch((err) => {
                console.log(err);
            });
            
      }, []); 

    return (
        <>
            <WhatsappBtn whatsAppNumber={ companyInfo.highlighted_phone  ? companyInfo.highlighted_phone : '+8801813639331'}></WhatsappBtn>
            <header className="construct-header-area">
                <div className="header-top-area">
                    <div className="header-top-overlay"></div>
                    <div className="container">
                        <div className="row" style={{height: '47px'}}>
                            <div className="col-md-6 col-sm-12 py-0">
                                <div className="header-top-left">
                                    <p style={{ color: "#fff", fontSize: '14px' }}>Have any question? {companyInfo.highlighted_phone  ? companyInfo.highlighted_phone : '+8801813639331'} || {companyInfo.phone  ? companyInfo.phone : '+8802224470414'}  </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 py-0">
                                <div className="header-top-right">
                                    <ul>
                                        {
                                            socialMedia.map(item => 
                                                <li><a href={item.link} target='_blank'><i className={item.icon_class_name}></i></a></li>
                                        
                                                )

                                        }
                                        {/* <li><a href="https://www.facebook.com/jtcbd24/" target='_blank'><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://wa.me/+8801813639331" target='_blank'><i className="fa fa-whatsapp"></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCOEnzrfLzc1OhQlUqnDPirg" target='_blank'><i className="fa fa-youtube"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-logo-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="construct-logo">
                                    <Link to="/">
                                        <img style={{ height: '12vh', width: 'auto' }} src={ companyInfo.logo  ? GET_URL + companyInfo.logo : logo} alt="site logo"  />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="logoright-section">
                                    <div className="single-logo-right">
                                        <div className="logo-right-icon">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="logo-right-text">
                                            <h4>call us at</h4>
                                            <p>All Days {companyInfo.available_time  ? companyInfo.available_time : '9:00 to 5:00'}</p>
                                        </div>
                                    </div>
                                    <div className="single-logo-right">
                                        <div className="logo-right-icon">
                                            <i className="fa fa-envelope-o"></i>
                                        </div>
                                        <div className="logo-right-text">
                                            <h4>Mail us</h4>
                                            <p> {companyInfo.highlighted_email  ? companyInfo.highlighted_email : 'jtc.asad@gmail.com'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navbar2></Navbar2>

                {/* modal */}
                <div className="modal fade" id="myModal" tabindex="-1" role="dialog">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="search_box_container">
                                                <form action="#">
                                                    <input type="text" placeholder="Search Here.." />
                                                    <button type="submit">
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;