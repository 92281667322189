import React, { useContext, useEffect, useState } from 'react';
import './TestimonialArea.css'
// import { EmployContext } from '../../../App';

import { UrlContext } from '../../../App';


import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const TestimonialArea = () => {
    // const [employ] = useContext(EmployContext)
    // const employThree = employ.slice(0, 3);
    // console.log(employThree);
    const [testimonial, setTestimonial] = useState([])
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {

        fetch(GET_URL + '/company/testimonial/')
            .then(response => response.json())
            .then((data) => {
                console.log(data)
                setTestimonial(data)
            })
            .catch((err) => {
                console.log(err);
            });
      }, []); 

    return (
        <section className="construct-testimonial-area section_100">
            <div className="testimonial-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                loop={true}
                                autoplay={{
                                    "delay": 3000,
                                    "disableOnInteraction": false
                                }}
                                // pagination={{
                                //     "clickable": true,
                                //     type: 'bullets',
                                // }}
                                // navigation={true}

                                className="mySwiper">
                                {
                                    testimonial.map(person =>
                                        <SwiperSlide className="single-testimonial">
                                            <div className="testimonial-image">
                                                <div className="testimonial-img-left">
                                                    <img src={person.photo} alt="testimonial" />
                                                </div>
                                                <div className="testimonial-img-right">
                                                    <h3>{person.name}</h3>
                                                    {/* <h3><a href="#">{person.name}</a></h3> */}
                                                    <p style={{ color: "#fff" }}>{person.designation}</p>
                                                </div>
                                            </div>
                                            <div className="testimonial-text">
                                                <p style={{ color: "#fff" }}>{person.speech.split(' ').slice(0, 100).join(" ") }</p>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialArea;