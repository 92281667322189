import React from 'react';
import AboutArea from '../../HomePage/AboutArea/AboutArea';
import Breadcromb from '../../Share/Breadcromb/Breadcromb';
// import Footer from '../../Share/Footer/Footer';
// import Header from '../../Share/Header/Header';
import AboutPromoBox from '../AboutPromoBox/AboutPromoBox';
import AboutTeam from '../AboutTeam/AboutTeam';
import AboutUs from '../AboutUs/AboutUs';
import { UrlContext } from '../../../App';
import { useEffect, useState , useContext } from 'react';

const About = () => {

    const [companyInfo, setCompanyInfo] = useState({})

       const url = useContext(UrlContext);
       const GET_URL = `${url}`;
       
       useEffect(() => {
           fetch(GET_URL + '/company/info/')
               .then(response => response.json())
               .then((data) => {
                // console.log(data)
                setCompanyInfo(data)
               })
               .catch((err) => {
                   console.log(err);
               });
       }, []);
    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            <Breadcromb directory={'About Us'} title={"ABOUT US"} page={'about'}></Breadcromb>

           
            {/* {
                companyInfo.profile ?  <iframe src={`${ GET_URL + companyInfo.profile}`} width="100%" height="550px"></iframe> : ''
            } */}
            <iframe src='http://jtcbd.net/assets/file/JTC-Company-Profile.pdf' width="100%" height="550px"></iframe>
            <AboutUs></AboutUs>
            <AboutTeam></AboutTeam>
            <div className='section_t_100'></div>
            <AboutArea></AboutArea>
            <AboutPromoBox></AboutPromoBox>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default About;