import React from 'react';
import ServiceArea from '../../HomePage/ServiceArea/ServiceArea';
import Breadcromb from '../../Share/Breadcromb/Breadcromb';
// import Footer from '../../Share/Footer/Footer';
// import Header from '../../Share/Header/Header';

const Services = () => {
    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            <Breadcromb directory={'Services'} title={"OUR SERVICES"}  page={'services'}></Breadcromb>
            <ServiceArea></ServiceArea>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default Services;