import React, {useContext, useState, useEffect} from 'react';
import Breadcromb from '../../Share/Breadcromb/Breadcromb';
// import Header from '../../Share/Header/Header';
import img1 from '../../../Assets/Licence/nl1.jpg'
import img2 from '../../../Assets/Licence/nl10.jpg'
import img3 from '../../../Assets/Licence/nl11.jpg'
import img4 from '../../../Assets/Licence/nl12.jpg'
import img5 from '../../../Assets/Licence/nl13.jpg'
import img6 from '../../../Assets/Licence/nl14.jpg'
import img7 from '../../../Assets/Licence/nl2.jpg'
import img8 from '../../../Assets/Licence/nl3.jpg'
import img9 from '../../../Assets/Licence/nl4.jpg'
import img10 from '../../../Assets/Licence/nl5.jpg'
import img11 from '../../../Assets/Licence/nl6.jpg'
import img12 from '../../../Assets/Licence/nl7.jpg'
import img13 from '../../../Assets/Licence/nl8.jpg'
import img14 from '../../../Assets/Licence/nl9.jpg'
// import Footer from '../../Share/Footer/Footer';
import { UrlContext } from '../../../App';

const Licence = () => {
    const imgList = [{image:img1}, {image:img7}, {image:img8}, {image:img9}, {image:img10}, {image:img11}, {image:img12}, {image:img13}, {image:img14}, {image:img2}, {image:img3}, {image:img4}, {image:img5}, {image:img6}]
    const [licence, setLicence] = useState(imgList)
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {
        fetch(GET_URL + '/company/licence/')
            .then(response => response.json())
            .then((data) => {
                if(data.length > 0){
                    console.log(data)
                    setLicence([])
                    setLicence(data)
                }
              
            })
            .catch((err) => {
                // console.log(err);
            });
    }, []);

    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            <Breadcromb directory={'about us'} title={'Our Licence'} page={'licence'}></Breadcromb>
            <section className="construct-about-page-area section_100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading">
                                <h4>Licence</h4>
                                <h2>Our Licence</h2>
                                {/* <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            licence.map(item =>
                                <div className='col-md-6'>
                                    <div className='p-2'>
                                        {/* <img src={`${img}`} alt="" /> */}
                                        <img src={item.image} alt="" />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default Licence;