import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar2.css'

const Navbar2 = () => {
  return (
    <div>
      <div className="mainmenu-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-dark">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul id='navbar-main' className="navbar-nav mr-auto text-uppercase ">

                    <li className="nav-item">
                      <NavLink className={(navInfo) => (navInfo.isActive ? "active nav-link" : "nav-link")} to="/">
                        Home <span className="sr-only">(current)</span>
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown">
                      <div className="nav-link dropdown-toggle" id="navbarDropdown0" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        ABOUT
                      </div>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to="/about" >Our Profile</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/about/licence">Our Licence</Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <div  className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services
                      </div>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to="/services">Our Services</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/services/medical">Medical Facility</Link>
                      </div>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <div className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Our Fly
                      </div>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to="/fly/singapore">Singapore</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/fly/uae">UAE</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/fly/Malaysia">Malaysia</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/fly/Cambodia">Cambodia</Link>
                      </div>
                    </li> */}
                    <li className="nav-item">
                      <NavLink className={(navInfo) => (navInfo.isActive ? "active nav-link" : "nav-link")} to="/contact">
                        contact
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className={(navInfo) => (navInfo.isActive ? "active nav-link" : "nav-link")} to="/admission">
                        Admission
                      </NavLink>
                    </li>
                  </ul>
                  {/* <ul className="navbar-nav ml-auto text-uppercase">
                    <NavLink className={(navInfo) => (navInfo.isActive ? "active nav-link" : "nav-link")} to="/getAdmission">
                      Get Admission
                    </NavLink>
                  </ul> */}
                </div>
              </nav>
            </div>
          </div>
        </div>

      </div>
    </div>


  );
};

export default Navbar2;