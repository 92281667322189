import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { UrlContext } from '../../../App';

import backImage from '../../../Assets/breadcromb/breadcromb.jpg'

const Breadcromb = ({directory, title, page}) => {
   
    const [pageBanner, setPageBanner] = useState({image: backImage })
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;
   
    useEffect(() => {
        fetch(GET_URL + '/statics/banners/?page='+page)
            .then(response => response.json())
            .then((data) => {
                console.log(data)
                if(data.length > 0){
                    setPageBanner(data[0])
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    return (
        <section className="construct-breadcromb-area section_100" style={{ backgroundImage : `url("${pageBanner.image}")`, backgroundRepeat: "no-repeat", backgroundSize : "cover"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="breadcromb">
                            <h2>{pageBanner.title ? pageBanner.title : title}</h2>
                            <ul>
                                <li><Link to="/">home</Link></li>
                                <li>/</li>
                                <li>{directory}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcromb;