import React from 'react';
import { Link } from 'react-router-dom';

const HireArea = () => {
    return (
        <header className="construct-hire-area">
            <div className="hire-bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="construct-hire-left">
                            <div className="hire-overlay"></div>
                            <h3>Lets make your dream with our professionals</h3>
                            <div className="hire-icon">
                                <i className="fa fa-home"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="construct-hire-right">
                            <Link to="/contact" className="construct-btn">start a project</Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HireArea;