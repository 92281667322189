import React, {useContext, useEffect, useState} from 'react';
import img1 from '../../../Assets/project/3.jpg';
import img2 from '../../../Assets/project/13.jpg';
import img3 from '../../../Assets/project/15.jpg';
import img4 from '../../../Assets/project/newpost5.jpg';
import img5 from '../../../Assets/project/newpost4.jpg';
import img6 from '../../../Assets/project/12.jpg';
import img7 from '../../../Assets/project/newpost1.jpg';
import img8 from '../../../Assets/project/newpost2.jpg';
import img9 from '../../../Assets/project/newpost3.jpg';
import { UrlContext } from '../../../App';

const ProjectArea = () => {

    const defaultEvent = [
        {
            image: `${img1}`,
            name: 'Mechanical Training',
            short_description: 'Train by an expert trainer with modern equipment'
        },
        {
            image: `${img2}`,
            name: 'Motivation',
            short_description: 'Given motivational speech for best feedback'
        },
        {
            image: `${img3}`,
            name: 'Safety',
            short_description: 'Given training with proper safety'
        },
        {
            image: `${img4}`,
            name: 'Consultancy',
            short_description: 'Consultant with the candidate for better opportunity'
        },
        {
            image: `${img5}`,
            name: 'Behavioral act',
            short_description: 'Give knowledge about behavior with the civilian'
        },
        {
            image: `${img6}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
        {
            image: `${img7}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
        {
            image: `${img8}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
        {
            image: `${img9}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
    ]


    const [event, setEvent] = useState(defaultEvent)
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {

        fetch(GET_URL + '/company/event/')
            .then(response => response.json())
            .then((data) => {
                if(data.length > 0){
                    setEvent([])
                    setEvent(data)
                }
               
            })
            .catch((err) => {
                console.log(err);
            });
      }, []); 

    return (
        <section className="construct-project-page-area section_100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-heading">
                            <h4>our Best Works</h4>
                            <h2>our recent events</h2>
                            <p>we are also offering professional services consultation, ensures prompt customer service and most importantly we can help our clients' save time by providing the best <span>ONE-STOP SOLUTIONS</span> for the various government & statutory departments as per our clients’ needs.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="construct-isotope-project">
                            {/* <div className="projectFilter project-btn">
                                <a href="#" data-filter="*" className="current">show all</a>
                                <a href="#" data-filter=".construction">construction</a>
                                <a href="#" data-filter=".renovation">renovation</a>
                                <a href="#" data-filter=".plumbing">plumbing</a>
                                <a href="#" data-filter=".flooring">flooring</a>
                            </div> */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="clearfix projectContainer projectContainer3column">
                                        {
                                            event.map(project =>
                                                <div key={project.name} className="element-item">
                                                    <div className="project-single-item img-contain-isotope">
                                                        <div>
                                                            <img src={project.image} className="img-responsive" alt="Image" />
                                                        </div>
                                                        <div className="project-img-overlay">
                                                            <h4>{project.name}</h4>
                                                            <p>{project.short_description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectArea;