import React from 'react';
// import Header from '../../Share/Header/Header';
import Breadcromb from '../../Share/Breadcromb/Breadcromb';
import hospitalImg from '../../../Assets/services/aic.jpg'
// import Footer from '../../Share/Footer/Footer';
import { UrlContext } from '../../../App';
import { useEffect, useContext, useState } from 'react';

const MedicalFacility = () => {

    const [medicalFaicilies, setMedicalFaicilies] = useState([])
    const [companyInfo, setCompanyInfo] = useState([])
    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {
        fetch(GET_URL + '/company/medical-facility/')
            .then(response => response.json())
            .then((data) => {
                // console.log(data);
                setMedicalFaicilies(data)
            })
            .catch((err) => {
                // console.log(err);
            });
            fetch(GET_URL + '/company/info/')
               .then(response => response.json())
               .then((data) => {
                // console.log(data)
                setCompanyInfo(data)
               })
               .catch((err) => {
                   console.log(err);
               });
    }, [])
    return (
        <div style={{ lineHeight: '26px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif' }}>
            {/* <Header></Header> */}
            <Breadcromb directory={'services'} title={"Our Medical Facilitys" } page={'medical'}></Breadcromb>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-6">
                        <div className='p-5'>
                            <h2 className='pb-3'><i style={{ color: 'red' }} className="fa fa-ambulance"></i>  Our Medical Facilitys.</h2>
                            <div>
                                {
                                    medicalFaicilies.map( item => 
                                        <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> {item.name}</p>
                                        )
                                }
                                {/* <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Blood Pressure.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Microscopic Examination.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Chest P/A view.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> ECG Test.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> HBSAG.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Allergy.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> URINE TEST.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> EYE (Color blindness) TEST.</p>
                                <p> <i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Ear & Nose Test.</p>
                                <p> <i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Diabetes.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> CBC Test.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Chemical Examination.</p>
                                <p><i style={{ fontSize: '18px' }} className="fa fa-crosshairs"></i> Physical Examination.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='p-5'>
                            <img src={ companyInfo.medical_facility_image ? GET_URL + companyInfo.medical_facility_image : hospitalImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default MedicalFacility;