import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import "swiper/css/bundle";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate
} from "react-router-dom";
import Preloader from './Components/Share/Preloader/Preloader';
// import Footer from './Components/Share/Footer/Footer';
import Home from './Components/HomePage/Home/Home';
import Error404 from './Components/Share/Error404/Error404';
import About from './Components/AboutPage/About/About';

// img
import Foyazur from '../src/Assets/Employe/Foyazur.jpeg';
import Foyazur1 from '../src/Assets/Employe/Foyazur1.jpeg';
import mukul from '../src/Assets/Employe/mukul.png';
import Helal from '../src/Assets/Employe/helal.jpg';
// import Imran from '../src/Assets/Employe/imrant.jpg';
import Abdul from '../src/Assets/Employe/mk.jpg';
import Faruk from '../src/Assets/Employe/fb.jpg';
import Mousumi from '../src/Assets/Employe/mou.jpg';
import Maliha from '../src/Assets/Employe/maliha.jpg';
import Saddam from '../src/Assets/Employe/Saddam.png';
import Solayman from '../src/Assets/Employe/Solayman.png';
import Monir from '../src/Assets/Employe/Monir.png';
import Taher from '../src/Assets/Employe/ABU_TAHER.jpeg';



import Contact from './Components/ContactPage/Contact/Contact';
import Licence from './Components/AboutPage/Licence/Licence';
import Services from './Components/ServicesPage/Services/Services';
import MedicalFacility from './Components/ServicesPage/MedicalFacility/MedicalFacility';
// import WhatsappBtn from './Components/Share/WhatsappBtn/WhatsappBtn';
import Admission from './Components/AdmissionPage/Admission';
import Malaysia from './Components/OurFlyPage/Malaysia';
import Singapore from './Components/OurFlyPage/Singapore';
import UAE from './Components/OurFlyPage/UAE';
import Cambodia from './Components/OurFlyPage/Cambodia';
// import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Certificate from './Components/Share/Certificate/Certificate';
import GetAdmission from './Components/GetAdmission/GetAdmission';


// admin pages //dev_kb
import Login from './Components/Admin/Login/Login';
import DashBoard from './Components/Admin/Dashboard/DashBoard';
import AdminAdmission from './Components/Admin/AdminAdmission/AdminAdmission';
import AdminSingapore from './Components/SingaporePage/AdminSingapore';
import AdminUAE from './Components/UAEPage/AdminUAE';
import AdminMalaysia from './Components/MalaysiaPage/AdminMalaysia';
import AdminTradePage from './Components/Admin/AdminTradePage/AdminTradePage';

import Header from './Components/Share/Header/Header';
import Footer from './Components/Share/Footer/Footer';
export const EmployContext = createContext();
export const AddressContext = createContext();
export const UrlContext = createContext();

function App() {
  const address = {
    location: 'House - 18, Member Bari Road, Dag - 909, Nishatnagar, Diabari, Turag, Uttara, Dhaka-1230, Bangladesh',
    email: 'jtc.asad@gmail.com',
    phone: '+8802224470414',
    mobile: '+8801813639331'
  }
  const employ = [
    {
      name: 'Foyazur Rahaman Asad',
      designation: 'CO Managing Director',
      img: `${Foyazur}`,
      img2: `${Foyazur1}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Md. Helal Uddin',
      designation: 'CO Director',
      img: `${Helal}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Md. Abdul Kayum',
      designation: 'CO General Manager',
      img: `${Abdul}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    // {
    //   name: 'Mr. Imran',
    //   designation: 'CO Head Of Marketing',
    //   img: `${Imran}`,
    //   speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    // },
    {
      name: 'Mukul Hasan',
      designation: 'CO Head of IT',
      img: `${mukul}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Mousumi Aktar',
      designation: 'CO Accounts Officer',
      img: `${Mousumi}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Maliha Aktar Maksuda',
      designation: 'CO Marketing Representative',
      img: `${Maliha}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Md. Faruk Bhoeya',
      designation: 'CO Computer Operator',
      img: `${Faruk}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Saddam Hossain',
      designation: 'Accounts Officer',
      img: `${Saddam}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Md. Solayman Ali (Nayon)',
      designation: 'Manpower Representative',
      img: `${Solayman}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Monir Hossain',
      designation: 'Office Boy',
      img: `${Monir}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
    {
      name: 'Md. Abu Taher',
      designation: 'Security Guard',
      img: `${Taher}`,
      speech: `JTC has been furnished with full facilities of Training on Construction Trades and Marine Trades. JTC imparts methodical training on both a theoretical and practical basis. JTC has been developed to meet the international requirements of the candidates and thus can face international tests and trials all the time`
    },
  ]
  

  // const url = "http://jtc.zay.co.com:83/";
  // const url = "http://jtcbd.net:82/";
  // const url = "http://192.168.0.165:8000/";
  // const url = "http://192.168.43.64:8000/";
  // const url = "http://192.168.0.239:8000/";
  // const url = "http://173.212.212.99:8010/";
  const url = "http://173.212.254.73:82/"; 
  // const url = "http://localhost:8000/";



  return (
    <BrowserRouter>
      <UrlContext.Provider value={url}>
        <EmployContext.Provider value={[employ]}>
          <AddressContext.Provider value={address}>
            {/* <ScrollToTop></ScrollToTop> */}
            <div className="App">
              <Preloader></Preloader>
              <Header></Header>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/about/licence" element={<Licence />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/medical" element={<MedicalFacility />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/admission" element={<Admission />} />
                <Route path="/fly" element={<Malaysia />} />
                <Route path="/fly/malaysia" element={<Malaysia />} />
                <Route path="/fly/uae" element={<UAE />} />
                <Route path="/fly/singapore" element={<Singapore />} />
                <Route path="/fly/Cambodia" element={<Cambodia />} />
                {/* <Route path='/certificate' element={<Certificate />} />
                <Route path='/getAdmission' element={<GetAdmission />} />

                <Route exact path='/admin/login' element={<Login />} />
                <Route exact path='/admin/dashboard' element={<DashBoard />} />
                <Route exact path='/admin/dashboard/admission' element={<AdminAdmission />} />
                <Route exact path='/admin/dashboard/singapore' element={<AdminSingapore />} />
                <Route exact path='/admin/dashboard/uae' element={<AdminUAE />} />
                <Route exact path='/admin/dashboard/malaysia' element={<AdminMalaysia />} />
                <Route exact path='/admin/dashboard/trade' element={<AdminTradePage />} /> */}

                <Route path="/404" element={<Error404 />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
              </Routes>
              <Footer></Footer>
            </div>
          </AddressContext.Provider>
        </EmployContext.Provider>
      </UrlContext.Provider>
    </BrowserRouter >
  );
};

export default App;
