import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddressContext } from '../../../App';
import logo from '../../../Assets/Logo/logo.jfif';
import img1 from '../../../Assets/project/3.jpg';
import img2 from '../../../Assets/project/13.jpg';
import img3 from '../../../Assets/project/15.jpg';
import img4 from '../../../Assets/project/newpost5.jpg';
import img5 from '../../../Assets/project/newpost4.jpg';
import img6 from '../../../Assets/project/12.jpg';
import img7 from '../../../Assets/project/newpost1.jpg';
import img8 from '../../../Assets/project/newpost2.jpg';
import img9 from '../../../Assets/project/newpost3.jpg';
import { UrlContext } from '../../../App';
const Footer = () => {
    const defaultEvent = [
        {
            image: `${img1}`,
            name: 'Mechanical Training',
            short_description: 'Train by an expert trainer with modern equipment'
        },
        {
            image: `${img2}`,
            name: 'Motivation',
            short_description: 'Given motivational speech for best feedback'
        },
        {
            image: `${img3}`,
            name: 'Safety',
            short_description: 'Given training with proper safety'
        },
        {
            image: `${img4}`,
            name: 'Consultancy',
            short_description: 'Consultant with the candidate for better opportunity'
        },
        {
            image: `${img5}`,
            name: 'Behavioral act',
            short_description: 'Give knowledge about behavior with the civilian'
        },
        {
            image: `${img6}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
        {
            image: `${img7}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
        {
            image: `${img8}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
        {
            image: `${img9}`,
            name: 'Entertainment',
            short_description: 'For stable and sound mental health need entertainment'
        },
    ]
    const address = useContext(AddressContext);
    const [headOffice, setheadOffice] = useState({})
    const [companyInfo, setcompanyInfo] = useState({})
    const [socialMedia, setSocialMedia] = useState([])
    
    const [events, setEvents] = useState(defaultEvent)

    const url = useContext(UrlContext);
    const GET_URL = `${url}`;

    useEffect(() => {

        fetch(GET_URL + '/company/info/')
            .then(response => response.json())
            .then((data) => {
                console.log(data)
               setcompanyInfo(data)
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(GET_URL + '/company/head-office/')
            .then(response => response.json())
            .then((data) => {
                console.log(data)
                setheadOffice(data)
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(GET_URL + '/company/event/')
            .then(response => response.json())
            .then((data) => {
                if(data.length > 0){
                    setEvents([])
                    setEvents(data)
                }
               
            })
            .catch((err) => {
                console.log(err);
            });
        fetch(GET_URL + '/company/social-media/')
            .then(response => response.json())
            .then((data) => {
                if(data.length > 0){
                    setSocialMedia(data)
                }
               
            })
            .catch((err) => {
                console.log(err);
            });
      }, []); 


    const clickToTop = () => {
        // console.log('clicked')
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    
    return (
        <footer className="construct-footer-area">
            <div className="construct-footer-top-area section_50" style={{ color: "#999" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="single-footer ">
                                <Link to="/">
                                    <img style={{ height: '10vh', width: 'auto' }} src={ companyInfo.logo  ? GET_URL + companyInfo.logo : logo} alt="footer logo" />
                                </Link>
                                <p style={{ color: "#999" }}> {companyInfo.short_description  ? companyInfo.short_description : "we are also offering professional services consultation, ensures prompt customer service and most importantly we can help our client’s save time by providing the best ONE-STOP SOLUTIONS for the various government & statutory departments as per our client’s needs."}</p>
                                <div className="footer-social-icon">
                                    <h3>Follow Us</h3>
                                    <ul className="footer-social">
                                      {  socialMedia.map( item => 
                                      
                                                <li className='m-1'>
                                                    <a href={item.link} target='_blank' className={item.class_name}>
                                                        <i className={item.icon_class_name}></i>
                                                    </a>
                                                </li>
                                      
                                          )

                                       }
                                        {/* <li className='m-1'>
                                            <a href="https://www.facebook.com/jtcbd24/" target='_blank' className="fb">
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li className='m-1'>
                                            <a href="https://wa.me/+8801813639331" target='_blank' className="twitter">
                                                <i className="fa fa-whatsapp"></i>
                                            </a>
                                        </li>
                                        <li className='m-1'>
                                            <a href="https://www.youtube.com/channel/UCOEnzrfLzc1OhQlUqnDPirg" target='_blank' className="gp">
                                                <i className="fa fa-youtube"></i>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="single-footer ">
                                <h3>quick as</h3>
                                <ul className="footer-widget">
                                    <li>
                                        <Link onClick={clickToTop} to="/about">
                                            <i className="fa fa-angle-right"></i>
                                            about us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={clickToTop} to="/services">
                                            <i className="fa fa-angle-right"></i>
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={clickToTop} to="/about/licence">
                                            <i className="fa fa-angle-right"></i>
                                            Our Licence
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={clickToTop} to="/contact">
                                            <i className="fa fa-angle-right"></i>
                                            Contact
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={clickToTop} to="/admission">
                                            <i className="fa fa-angle-right"></i>
                                            Admission
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="single-footer ">
                                <h3>head office</h3>
                                <p style={{ color: "#999" }}>{headOffice.address  ? headOffice.address : address.location}</p>
                                <p className="contact-details" style={{ color: "#999" }}>
                                    <i className="fa fa-phone"></i>
                                    {headOffice.phone  ? headOffice.phone : address.phone} <br />
                                    <i className="fa fa-phone"></i>
                                    {headOffice.phone2  ? headOffice.phone2 : address.mobile}
                                </p>
                                <p className="contact-details" style={{ color: "#999" }}>
                                    <i className="fa fa-envelope-o"></i>
                                    {headOffice.email  ? headOffice.email : address.email}
                                </p>
                                <p className="contact-details" style={{ color: "#999" }}>
                                    <i className="fa fa-hourglass-2"></i>
                                    {headOffice.available_time  ? headOffice.available_time : '9:00 a.m - 5:00 pm'} 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="single-footer ">
                                <h3>Gallery</h3>
                                <div className="single-footer-insta">
                                    {
                                        events.slice(0, 9).map(event =>
                                            <div key={event.name} className="insta-post">
                                                <a to="#">
                                                    <img src={event.image} alt="gallery" />
                                                </a>
                                                <div className="overlay-insta"></div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="construct-footer-bottom-area section_15">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="copyright-left">
                                <p  style={{ color: "#999" }}>Copyrights &copy; {(new Date()).getFullYear()} <a style={{ color: 'Green' }} href="http://www.zay.co.com/" target='_blank'>ZAY CORPORATION</a>. All Rights Reserved. </p>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="copyright-right">
                                <p>Built with <i className="fa fa-heart"></i> from<span>Themescare</span></p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;